import React, { useState, useEffect, Suspense } from "react";
import { Link } from "react-router-dom";
import "./home.css";
import { Helmet } from "react-helmet-async";
import cgivideo from "../../assests/home/cgi video.mp4";
import caroaselimg1 from "../../assests/home/banner-1.avif";
import caroaselimg3 from "../../assests/home/banner-2.avif";
import caroaselimg2 from "../../assests/home/banner-3.avif";
import noida62 from "../../assests/property-detail/noida/altf-noida-62/1 (1).avif";
import banner from "../../assests/home/website banner@3x.avif";
import mbsmallbanner from "../../assests/home/mbhomebanner.avif";
import locationsbtn from "../../assests/home/locationsbtn.png";
import workspacebtn from "../../assests/home/workspacebtn.png";
import useLazyLoadOnScroll from "../../components/lazyloadcomponent";
const Newcards = React.lazy(() => import("./newcards"));
const Ourcliente = React.lazy(() => import("./ourcliente"));
const Faq = React.lazy(() => import("./faq"));
const EnquiryFormModal = React.lazy(() =>
  import("../../components/pop-up-enquire/pop-up-enquiry")
);

const Virtualtour = React.lazy(() => import("./virtualtour"));

const Home = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const showOurClient = useLazyLoadOnScroll("our-client-section");
  const showFaq = useLazyLoadOnScroll("faq-section");
  const [videoSrc, setVideoSrc] = useState("");

  useEffect(() => {
    fetch(cgivideo)
      .then((response) => response.blob())
      .then((blob) => setVideoSrc(URL.createObjectURL(blob)));
  }, []);

  const toggleReadMore = () => {
    setShowMore(!showMore);
  };

  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [showVirtualTour, setShowVirtualTour] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("virtual-tour-section");
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top < window.innerHeight) {
          setShowVirtualTour(true); // Render Virtualtour when in viewport
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <Suspense fallback={<div className="loading">Loading Form...</div>}>
        <EnquiryFormModal isOpen={isModalOpen} onClose={handleModalToggle} />
      </Suspense>
      <Helmet>
        {/* Meta Title */}
        <title>
          alt.f coworking - Seen on Shark Tank India - Best Shared Office Space
        </title>

        {/* Meta Description */}
        <meta
          name="description"
          content="alt.f coworking, seen on Shark Tank India, offers the best shared office space in Delhi, Gurgaon, Noida, and Hyderabad. Book your seat in our coworking space now."
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="coworking space, coworking space in delhi, coworking space in noida, coworking space in hyderabad, coworking space in gurgaon, coworking space in india, coworking in gurgaon, coworking in noida, coworking in delhi, coworking in hyderabad, office space for rent, office space, workspace, coworking, shared office space, coworking space near me, team rooms, meeting rooms, virtual office, flexible office, fixed office, private office, business address, gst registration, business registration, company registration, coworking in india, coworking spaces, shared workspace, shark tank india, shark tank, shark tank india season 4, altf coworking shark tank, alt.f coworking shark tank india, seen on shark tank india"
        />

        {/* Robots */}
        <meta name="robots" content="index, follow" />

        {/* Canonical */}
        <link rel="canonical" href="https://www.altfcoworking.com/" />

        {/* Author & Publisher */}
        <meta name="author" content="alt.f coworking" />
        <meta name="publisher" content="alt.f coworking" />

        {/* Open Graph (OG) Meta Tags */}
        <meta
          property="og:title"
          content="alt.f coworking - Seen on Shark Tank India - Best Shared Office Space"
        />
        <meta
          property="og:description"
          content="alt.f coworking, seen on Shark Tank India, offers the best shared office space in Delhi, Gurgaon, Noida, and Hyderabad. Book your seat in our coworking space now."
        />
        <meta
          property="og:image"
          content="https://www.altfcoworking.com/static/media/website%20banner@3x.ea71c8d54bde97ce0994.avif"
        />
        <meta property="og:url" content="https://www.altfcoworking.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="alt.f coworking" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="alt.f coworking - Seen on Shark Tank India - Best Shared Office Space"
        />
        <meta
          name="twitter:description"
          content="alt.f coworking, seen on Shark Tank India, offers the best shared office space in Delhi, Gurgaon, Noida, and Hyderabad. Book your seat in our coworking space now."
        />
        <meta
          name="twitter:image"
          content="https://www.altfcoworking.com/static/media/website%20banner@3x.ea71c8d54bde97ce0994.avif"
        />
        <meta
          name="twitter:image:alt"
          content="alt.f coworking - Seen on Shark Tank India - Best Shared Office Space"
        />
        <meta name="twitter:site" content="@altfcoworking" />
        <meta name="twitter:creator" content="@altfcoworking" />
      </Helmet>

      <div
        id="carouselExampleAutoplaying"
        className="carousel slide d-none d-lg-block pt-5 container"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="15000">
            <div className="d-flex flex-row justify-content-center align-items-center gap-3  carosel-container">
              <div
                style={{ width: "50%", height: "280px" }}
                className="d-flex flex-column justify-content-between"
              >
                <div>
                  <h5>Find your office</h5>
                  <h5>in our office.</h5>
                </div>

                <h2 className="mt-2">
                  Superior value for micro & small businesses.
                </h2>

                <div className="d-flex flex-row mt-4">
                  <Link to="/coworking-space/">
                    <button className="explorebtn">Explore Workspaces</button>
                  </Link>
                </div>
              </div>

              <div className="bannervideo">
                <Helmet>
                  <link rel="preload" as="video" href={videoSrc} />
                </Helmet>
                <video
                  src={videoSrc}
                  width="100%"
                  height="100%"
                  fetchpriority="high"
                  autoPlay
                  muted
                  loop
                  playsInline
                  preload="auto"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>

          {/* <div className="carousel-item " data-bs-interval="5000">
            <div className="d-flex flex-row justify-content-center align-items-center gap-3  carosel-container">
              <Helmet>
                <link rel="preload" as="image" href={banner} />
              </Helmet>

              <img
                rel="preload"
                src={banner}
                alt="img"
                className="sharkbannerimg"
                fetchpriority="high"
                width="1200"
                height="400"
              />
              <Link to="/coworking-space/">
                <button className="readourstorybtnlp">
                  Explore Workspaces
                </button>
              </Link>
            </div>
          </div> */}

          <div className="carousel-item" data-bs-interval="5000">
            <div className="d-flex flex-row justify-content-center align-items-center gap-3  carosel-container">
              <div style={{ width: "50%" }}>
                <h5>alt.f Sector 62</h5>
                <h5>Noida</h5>

                <h2 className="mt-4 subheadinghome1">30 days rent-free</h2>
                <h2>Where small businesses achieve greatness.</h2>

                <div className="d-flex flex-row mt-4">
                  <Link to="/coworking-space/noida/altf-noida-62/">
                    <button className="explorebtn">Explore Workspace</button>
                  </Link>

                  <button className="enquirebtn" onClick={handleModalToggle}>
                    Enquire now
                  </button>
                </div>
              </div>

              <div>
                <Helmet>
                  <link rel="preload" as="image" href={noida62} />
                </Helmet>

                <img src={noida62} alt="img" className="carosele-image" />
              </div>
            </div>
          </div>

          <div className="carousel-item" data-bs-interval="5000">
            <div className="d-flex flex-row justify-content-center align-items-center gap-3  carosel-container">
              <div style={{ width: "50%" }}>
                <h5>alt.f Sector 58 </h5>
                <h5>Noida</h5>

                <h2>Best value for small & micro businesses.</h2>

                <div className="d-flex flex-row mt-4">
                  <Link to="/coworking-space/noida/altf-noida-58">
                    <button className="explorebtn">Explore Workspace</button>
                  </Link>

                  <button className="enquirebtn" onClick={handleModalToggle}>
                    Enquire now
                  </button>
                </div>
              </div>

              <div>
                <Helmet>
                  <link rel="preload" as="image" href={caroaselimg1} />
                </Helmet>

                <img src={caroaselimg1} alt="img" className="carosele-image" />
              </div>
            </div>
          </div>

          <div className="carousel-item" data-bs-interval="5000">
            <div className="d-flex flex-row justify-content-center align-items-center gap-3  carosel-container">
              <div style={{ width: "50%" }}>
                <h5>alt.f Financial District</h5>
                <h5>Hyderabad</h5>

                <h2 className="mt-4 subheadinghome1">
                  Crafted for Micro Businesses
                </h2>
                <h2>Kapil Kavuri Hub. 144, Financial District</h2>

                <div className="d-flex flex-row mt-4">
                  <Link to="/coworking-space/gurgaon/altf-orchid-business-park">
                    <button className="explorebtn">Explore Workspace</button>
                  </Link>

                  <button className="enquirebtn" onClick={handleModalToggle}>
                    Enquire now
                  </button>
                </div>
              </div>

              <div>
                <Helmet>
                  <link rel="preload" as="image" href={caroaselimg3} />
                </Helmet>

                <img
                  src={caroaselimg3}
                  alt="img"
                  className="carosele-image"
                  loading="lazy"
                />
              </div>
            </div>
          </div>

          <div className="carousel-item" data-bs-interval="5000">
            <div className="d-flex flex-row justify-content-center align-items-center gap-3  carosel-container">
              <div style={{ width: "50%" }}>
                <h5>alt.f Sector 142</h5>
                <h5>Noida Expressway</h5>

                <h2 className="mt-4 subheadinghome1">15 days rent-free</h2>
                <h2>Best value for micro businesses</h2>

                <div className="d-flex flex-row mt-4">
                  <Link to="/coworking-space/noida/altf-noida-142">
                    <button className="explorebtn">Explore Workspace</button>
                  </Link>

                  <button className="enquirebtn" onClick={handleModalToggle}>
                    Enquire now
                  </button>
                </div>
              </div>

              <div>
                <Helmet>
                  <link rel="preload" as="image" href={caroaselimg2} />
                </Helmet>

                <img
                  src={caroaselimg2}
                  alt="img"
                  className="carosele-image"
                  loading="lazy"
                />
              </div>
            </div>{" "}
          </div>
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* <div className="d-lg-none">
        <div className="mb-homebg">
        
          <h5>Superior value for micro businesses</h5>
          <h4>Gurgaon | Delhi | Noida</h4>
        </div>
      </div> */}

      <div className="d-flex flex-column justify-content-center align-items-center">
        <div style={{ maxWidth: "400px" }}>
          <div className="d-lg-none  mb-2 ">
            <img src={mbsmallbanner} alt="img" className="mbbannerimg" />
          </div>

          <div className="d-lg-none mb-3 w-100">
            <div className="d-flex flex-row justify-content-around align-items-center">
              <Link to="/coworking-space/">
                <img
                  src={locationsbtn}
                  alt="img"
                  className="homelocationsbtn"
                />
              </Link>

              <Link to="/workspace-solutions/">
                <img src={workspacebtn} alt="img" className="homelocationsbtn" />
              </Link>
            </div>

            <div className="bannervideo">
              <video
                src={videoSrc}
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
                playsInline
                preload="auto"
                fetchpriority="high"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>

      <Suspense fallback={<div className="loading">Loading...</div>}>
        <Newcards />
      </Suspense>

      <div id="virtual-tour-section">
        {showVirtualTour && (
          <Suspense
            fallback={<div className="loading">Loading Virtual Tour...</div>}
          >
            <Virtualtour />
          </Suspense>
        )}
      </div>

      <div id="our-client-section">
        {showOurClient && (
          <Suspense
            fallback={<div className="loading">Loading Clients...</div>}
          >
            <Ourcliente />
          </Suspense>
        )}
      </div>

      <div id="faq-section">
        {showFaq && (
          <Suspense fallback={<div className="loading">Loading FAQs...</div>}>
            <Faq />
          </Suspense>
        )}
      </div>

      <div className="webcontenthomepage w-100">
        <section>
          <div className="container">
           

            <h1>
              alt.f coworking - Seen On Shark Tank India - Best Shared Office
              Space
            </h1>

            <p>
              alt.f coworking made a powerful impact on Shark Tank India Season
              4, showcasing its innovative approach to flexible office spaces
              and redefining the coworking industry. With a strong vision to
              provide affordable, premium, and scalable workspaces, alt.f
              coworking has emerged as a game-changer for startups, MSMEs, small
              businesses, and micro businesses.
            </p>

            <p>
              One of the biggest challenges faced by micro businesses, startups,
              and small businesses is the high cost and long-term commitments of
              traditional office spaces for rent. alt.f coworking is solving
              this problem by offering cost-effective, best shared office space
              for rent that minimise the burden of upfront investments,
              maintenance costs, and long rental amount. Our flexible membership
              plans, day passes, meeting rooms, team rooms, director's cabin and
              fully equipped office suites allow businesses to scale
              effortlessly while focusing completely on growth.
            </p>

            <p>
              Being featured on Shark Tank India Season 4 validates our vision
              and mission to create affordable and best coworking spaces
              tailored to modern micro and small business needs. Our offerings
              include virtual office for gst registration, virtual office for
              business address, virtual office for business registration,
              private office solutions, and flexible office solutions, designed
              to increase productivity, collaboration, and team building. With
              prime locations, top-notch amenities, and a hassle-free setup,
              alt.f coworking is the ideal workspace solution for startups and
              small businesses looking to scale efficiently.
            </p>

            <p>
              The Shark Tank India recognition further strengthens our
              commitment to transforming the way businesses operate, providing
              them with flexible, professional, and cost-effective coworking
              environments. As we continue to expand, alt.f coworking remains
              dedicated to empowering micro businesses, startups, and
              enterprises with the best-in-class office spaces tailored for
              success.
            </p>

            <h2>
              alt.f coworking Space In Delhi, Gurgaon, Noida, And Hyderabad
            </h2>

            <p>
              <a href="https://www.altfcoworking.com/">alt.f coworking</a> is a
              leading coworking space provider in the Delhi, Gurgaon, Noida, and
              Hyderabad. We are the epitome of modern working, where
              professionalism meets comfort. And our workspaces are the
              powerhouse of motivation and innovation. At alt.f coworking, you
              get a lively work ambiance where you are free to work efficiently
              and, hence, deliver maximum productivity at work. The work
              atmosphere is suitable for startup owners, especially the
              bootstrapped ones, as you get an office space at minimum pricing.
              We serve as a coworking industry across various states in India,
              primarily covering the National Capital Region. Our spaces provide
              a safe, secure and friendly environment for working professionals,
              and networking is simpler as people from different work domains
              work under one roof. Founders and CEOs of growing companies can
              network proficiently here and increase their connections for a
              better business future and expansion. alt.f coworking is the best
              company if you are looking for a{" "}
              <a href="https://www.altfcoworking.com/coworking-space/delhi/">
                coworking space in Delhi
              </a>
              ,{" "}
              <a href="https://www.altfcoworking.com/coworking-space/noida/">
                coworking space in Noida
              </a>
              ,
              <a href="https://www.altfcoworking.com/coworking-space/gurgaon/">
                {" "}
                coworking space in Gurgaon
              </a>
              , and{" "}
              <a href="https://www.altfcoworking.com/coworking-space/hyderabad/">
                coworking space in Hyderabad
              </a>
              .
            </p>

            <h2>
              Best Coworking Spaces At alt.f coworking In Delhi, Gurgaon, Noida,
              And Hyderabad
            </h2>
            <p>
              We offer the best coworking spaces in over 18 prime areas in Delhi
              NCR and Hyderabad like Golf Course Extension Road Gurgaon, MG Road
              Gurgaon, Okhla Delhi, Financial District Hyderabad, Hitec City
              Hyderabad, Golf Course Road Gurgaon, Sohna Road Gurgaon, Sector 58
              Noida, Sector 62 Noida, Sector 132 Noida, Sector 142 Noida, Sector
              68 Noida, and more, and are planning to grow our coworking spaces
              as well. By covering Delhi, Gurgaon, Noida, and Hyderabad regions,
              we have set up a strong presence within the coworking industry and
              are planning to expand to other regions of the country in the near
              future. Booking a coworking space at alt.f coworking is easy.
              Simply contact our experts, familiarise yourself with our terms
              and conditions and space regulations, and be ready to experience a
              workspace like never before. alt.f coworking provides all kinds of
              office space solutions, including dedicated desks, private cabins,
              director cabins, virtual offices and even event spaces. Book the
              one that suits your company's needs and improve your working life
              with us. Young professionals and growing companies consisting of
              designers or marketers should consider choosing our office to
              create a healthier, more positive work environment and encounter
              growth opportunities.
            </p>

            <h3>
              <a href="https://www.altfcoworking.com/workspace-solutions/private-office/">
                Private Office
              </a>
            </h3>
            <p>
              A private office space solution with alt.f coworking offers a
              dedicated workspace to work according to your conditions and team
              preferences. It brings in the team rooms that consist of a
              comfortable desk, ergonomic chair, and ample storage space. Apart
              from that, individuals can also access office suites and director
              cabins. Whether you are an entrepreneur, a startup, or a part of a
              large team, alt.f coworking Private office is here to serve you
              the workspace as you choose to work on your own terms.
            </p>

            <h4>
              <a href="https://www.altfcoworking.com/workspace-solutions/private-office/team-rooms/">
                Team Rooms
              </a>
            </h4>

            <p>
              alt.f coworking provides team rooms designed for growing
              businesses, startups, and collaborative teams. These fully
              furnished spaces offer a dynamic work environment with ergonomic
              seating, spacious desks, and modern infrastructure. Ideal for
              teams that thrive in a shared yet private setup, our team rooms
              ensure productivity with high-speed internet, meeting room access,
              and a vibrant coworking atmosphere.
            </p>

            <h4>
              <a href="https://www.altfcoworking.com/workspace-solutions/private-office/director-cabins/">
                Director Cabins
              </a>
            </h4>

            <p>
              For executives and business leaders, director cabins at alt.f
              coworking offer a private and professional workspace. These cabins
              are designed for focused work, confidential discussions, and
              strategic planning. Equipped with high-end furniture,
              soundproofing, and seamless connectivity, our director cabins
              provide an exclusive space to manage operations efficiently while
              enjoying the benefits of a coworking ecosystem.
            </p>

            <h4>
              <a href="https://www.altfcoworking.com/workspace-solutions/private-office/office-suites/">
                Office Suites
              </a>
            </h4>

            <p>
              alt.f coworking’s office suites cater to companies seeking a fully
              functional, self-contained workspace. These suites combine privacy
              with the flexibility of a shared environment, offering dedicated
              workstations, meeting rooms, and breakout areas. Perfect for
              businesses that need a long-term yet adaptable office solution,
              our office suites come with premium amenities, 24/7 access, and
              complete operational support for a seamless work experience.
            </p>

            <h4>Dedicated Desks</h4>
            <p>
              Having your own desk creates your own space. It's part of a
              professional work environment that offers the perfect combination
              of focus and community. Additionally, having your own desk
              provides several benefits, such as access to meeting rooms,
              printing services, and other shared amenities. Dedicated desks are
              ideal for:
            </p>
            <ul>
              <li>Team sizes of 1-4 members.</li>
              <li>Small and medium businesses.</li>
              <li>Special seat for one person only.</li>
              <li>Access to meeting rooms and common areas.</li>
            </ul>

            <h4>Quick Seating Setup</h4>
            <p>
              If you need a coworking space for a short time period and avoid
              the overhead charges associated with an office space? Choose a
              quick seating setup by alt.f coworking. Experience the convenience
              of a flexible shared workspace. Book a cabin in a coworking space
              to fulfil your temporary company needs without the hassle of
              long-term leases or contracts. Whether you're looking for a quiet
              environment to focus on work or a dynamic space to socialise and
              collaborate with like-minded professionals, our temporary seating
              plans offer a wide range of amenities and services and you can
              choose what suits your company requirements the best.
            </p>
            <p>alt.f coworking's quick seating setup is ideal for:</p>
            <ul>
              <li>Small businesses</li>
              <li>Workspace for teams of 4 or more</li>
              <li>Allows access to common areas and amenities</li>
            </ul>

            <h3>
              <a href="https://www.altfcoworking.com/workspace-solutions/virtual-office/">
                Virtual Office
              </a>
            </h3>
            <p>
              A virtual office space solution allows companies and business
              persons to work remotely as per their choice and access a
              proficient address and office facilities without the burden of
              maintaining a physical office. We offer{" "}
              <a href="https://www.altfcoworking.com/workspace-solutions/virtual-office/delhi/">
                virtual office in Delhi
              </a>
              ,{" "}
              <a href="https://www.altfcoworking.com/workspace-solutions/virtual-office/noida/">
                virtual office in Noida
              </a>
              ,{" "}
              <a href="https://www.altfcoworking.com/workspace-solutions/virtual-office/hyderabad/">
                virtual office in Hyderabad
              </a>
              , and{" "}
              <a href="https://www.altfcoworking.com/workspace-solutions/virtual-office/gurgaon/">
                virtual office in Gurgaon
              </a>
              , in the most prime locations, therefore, with our virtual office,
              you get a professional business address, and added prestige due to
              prime locales, access to meeting rooms, and a proficient work
              setup, at minimal pricing. You get professional workspace
              facilities without the burden of administrative and maintenance
              costs. Facilities like mail handling and digital branding are
              readily available with our virtual office plan. There are three
              plans for our virtual office:
            </p>

            <h4>
              <a href="https://www.altfcoworking.com/workspace-solutions/virtual-office/business-address-plan/">
                Business Address Plan
              </a>
            </h4>
            <p>
              With this virtual office plan at alt.f coworking, you get a
              proficient business address and added benefits like meeting room
              access, concierge, and even mail handling services. Another
              advantage of choosing alt.f's virtual office plan is that we offer
              digital branding for your business, which adds a reputation to
              your organisation. And since all of our office spaces are located
              in the most prime areas of the cities, businesses earn credibility
              with our plan.
            </p>

            <h4>
              <a href="https://www.altfcoworking.com/workspace-solutions/virtual-office/gst-registration-plan/">
                GST Registration Plan
              </a>
            </h4>
            <p>
              Under this plan, documentation for GST registration of the company
              is offered to you which is a simple process and not very
              time-consuming. alt.f coworking facilitates documentation, making
              GST registration very easy and convenient so that various
              platforms, such as startups and small businesses, can easily
              access virtual office space for GST registration. All the benefits
              of a business address plan are included here.
            </p>

            <h4>
              <a href="https://www.altfcoworking.com/workspace-solutions/virtual-office/business-registration-plan/">
                Business Registration Plan
              </a>
            </h4>
            <p>
              After the GST process, it is very easy to sign up for your
              business registration and enjoy all the benefits of a Virtual
              Office. All the provisions of a business address plan are included
              in this plan.
            </p>

            <h3>
              <a href="https://www.altfcoworking.com/workspace-solutions/flexible-office/">
                Flexible Office
              </a>
            </h3>
            <p>
              Flexible space offers a helpful office solution for start-ups and
              freelancers. It is a complete office with no set-up costs.
              Companies find flexible office spaces advantageous in all aspects
              as they come with many perks, from dedicated workspaces to
              amenities and furnishings. In expansion, these workspaces are sure
              to boost collaboration and group morale. With alt.f coworking,
              flexible office space allows your business to operate with
              flexibility. All the provisions facilitate as per the demand and
              choice. In Flexible office, we mainly offer two categories i.e.,
              Day Pass and Meeting Rooms.
            </p>

            <h4>
              <a href="https://www.altfcoworking.com/workspace-solutions/flexible-office/day-pass/">
                Day Pass
              </a>
            </h4>
            <p>
              Our Day Pass is perfect for professionals, freelancers, and remote
              workers who need a workspace for a day without long-term
              commitments. It provides access to a premium coworking environment
              with high-speed internet, ergonomic seating, and essential office
              amenities. Whether you're working on a project, attending virtual
              meetings, or simply seeking a productive space, the Day Pass
              ensures flexibility and convenience.
            </p>

            <h4>
              <a href="https://www.altfcoworking.com/workspace-solutions/flexible-office/meeting-rooms/">
                Meeting Rooms
              </a>
            </h4>
            <p>
              alt.f coworking’s Meeting Rooms are designed for brainstorming
              sessions, client meetings, interviews, and team discussions. These
              fully equipped spaces come with modern audiovisual facilities,
              high-speed connectivity, whiteboards, and comfortable seating.
              Available for hourly or full-day bookings, our meeting rooms
              provide a professional setting to collaborate, strategize, and
              execute ideas effectively.
            </p>

            <h2>
              Why choose alt.f coworking for coworking space in Delhi, Gurgaon,
              Noida, and Hyderabad?
            </h2>
            <p>
              alt.f coworking understands what founders and working
              professionals need in their business journey, and what are the
              most significant challenges faced by them. It is workspace related
              issues and the high expenses of managing an entire office.
              Therefore, we have come up with office space solutions which will
              help them enhance and better the work lives of growing
              professionals. We at alt.f coworking offer all types of workspaces
              for a diverse range of businesses. Our workspaces include
              dedicated desks, director cabins, meeting rooms, team rooms,
              office suites and even virtual offices.
            </p>

            <p>
              For any aged professionals, young or old, who demand a hint of
              networking in the offices, alt.f coworking is the one-stop
              solution. Here, you would find professionalism, a sense of
              comfort, and ample opportunities for networking as business
              persons from different work sectors work in our coworking space.
              alt.f coworking is the best place for people wishing to work in
              harmony and who need tech-equipped workstations and meeting rooms
              alongside modern amenities to ensure smooth business operations.
              We understand the pain points of businesses in this expensive
              world. Hence, we offer cost-effective workspace solutions for your
              betterment. We offer an environment where you work smoothly and
              get an opportunity to expand and grow connections for a better
              future. Choose alt.f coworking, and choose the best workspaces.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Home;
